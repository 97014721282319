import React from 'react';
import i18n from './src/config/i18n';
import { I18nextProvider } from 'react-i18next';
import { WidgetDemoProvider } from './src/context/WidgetDemo';
import { LanguageProvider } from './src/context/Language';
import './src/styles/global.css';
import 'swiper/css';
import 'swiper/css/pagination';

export const onRouteUpdate = () => {
	if (typeof window !== `undefined`) {
		window.scrollTo(0, 0);
	}
};

export const shouldUpdateScroll = args => {
	return false;
};

export const wrapRootElement = ({ element }) => {
	return (
		<LanguageProvider>
			<I18nextProvider i18n={i18n}>
				<WidgetDemoProvider>{element}</WidgetDemoProvider>
			</I18nextProvider>
		</LanguageProvider>
	);
};
