import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

function useBackgroundColor() {
	const [backgroundColor, setBackgroundColor] = useState<string>('#FFFFFF');
	return [backgroundColor, setBackgroundColor];
}

function useColor() {
	const [color, setColor] = useState<string>('#004AFF');
	return [color, setColor];
}

function useTypography() {
	const [typography, setTypography] = useState<{
		value: string;
		label: string;
	}>({
		value: 'Roboto',
		label: 'Roboto'
	});
	const options: any = useMemo(
		() => [
			{ value: 'Arial', label: 'Arial' },
			{
				value: 'Roboto',
				label: 'Roboto'
			},
			{
				value: 'Sans-serif',
				label: 'Sans-serif'
			},
			{
				value: 'Serif',
				label: 'Serif'
			}
		],
		[]
	);
	return [typography, setTypography, options];
}

function usePayButton() {
	const [active, setActive] = useState<boolean>(true);
	return [active, setActive];
}

function useAmountLine() {
	const [amountLine, setAmountline] = useState<boolean>(true);
	return [amountLine, setAmountline];
}

function useDisplayMode() {
	const { t } = useTranslation();
	const [mode, setMode] = useState({
		value: 'list',
		label: t('LIST')
	});
	const options: any = useMemo(
		() => [
			{
				value: 'list',
				label: t('LIST')
			},
			{
				value: 'thumbnail',
				label: t('THUMBNAIL')
			}
		],
		[t]
	);
	return [mode, setMode, options];
}

export { useBackgroundColor, useColor, useTypography, usePayButton, useAmountLine, useDisplayMode };
