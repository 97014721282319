exports.components = {
  "component---src-components-templates-checkout-tsx": () => import("./../../../src/components/templates/checkout.tsx" /* webpackChunkName: "component---src-components-templates-checkout-tsx" */),
  "component---src-components-templates-classic-template-tsx": () => import("./../../../src/components/templates/classicTemplate.tsx" /* webpackChunkName: "component---src-components-templates-classic-template-tsx" */),
  "component---src-components-templates-connexion-tsx": () => import("./../../../src/components/templates/connexion.tsx" /* webpackChunkName: "component---src-components-templates-connexion-tsx" */),
  "component---src-components-templates-contact-tsx": () => import("./../../../src/components/templates/contact.tsx" /* webpackChunkName: "component---src-components-templates-contact-tsx" */),
  "component---src-components-templates-documentation-tsx": () => import("./../../../src/components/templates/documentation.tsx" /* webpackChunkName: "component---src-components-templates-documentation-tsx" */),
  "component---src-components-templates-homepage-tsx": () => import("./../../../src/components/templates/homepage.tsx" /* webpackChunkName: "component---src-components-templates-homepage-tsx" */),
  "component---src-components-templates-infrastructure-tsx": () => import("./../../../src/components/templates/infrastructure.tsx" /* webpackChunkName: "component---src-components-templates-infrastructure-tsx" */),
  "component---src-components-templates-job-offer-template-tsx": () => import("./../../../src/components/templates/jobOfferTemplate.tsx" /* webpackChunkName: "component---src-components-templates-job-offer-template-tsx" */),
  "component---src-components-templates-join-us-tsx": () => import("./../../../src/components/templates/joinUs.tsx" /* webpackChunkName: "component---src-components-templates-join-us-tsx" */),
  "component---src-components-templates-orchestration-tsx": () => import("./../../../src/components/templates/orchestration.tsx" /* webpackChunkName: "component---src-components-templates-orchestration-tsx" */),
  "component---src-components-templates-our-clients-tsx": () => import("./../../../src/components/templates/ourClients.tsx" /* webpackChunkName: "component---src-components-templates-our-clients-tsx" */),
  "component---src-components-templates-our-history-tsx": () => import("./../../../src/components/templates/ourHistory.tsx" /* webpackChunkName: "component---src-components-templates-our-history-tsx" */),
  "component---src-components-templates-our-team-tsx": () => import("./../../../src/components/templates/ourTeam.tsx" /* webpackChunkName: "component---src-components-templates-our-team-tsx" */),
  "component---src-components-templates-payment-solution-providers-tsx": () => import("./../../../src/components/templates/paymentSolutionProviders.tsx" /* webpackChunkName: "component---src-components-templates-payment-solution-providers-tsx" */),
  "component---src-components-templates-personal-data-tsx": () => import("./../../../src/components/templates/personalData.tsx" /* webpackChunkName: "component---src-components-templates-personal-data-tsx" */),
  "component---src-components-templates-post-template-tsx": () => import("./../../../src/components/templates/postTemplate.tsx" /* webpackChunkName: "component---src-components-templates-post-template-tsx" */),
  "component---src-components-templates-psp-template-tsx": () => import("./../../../src/components/templates/pspTemplate.tsx" /* webpackChunkName: "component---src-components-templates-psp-template-tsx" */),
  "component---src-components-templates-reconciliation-tsx": () => import("./../../../src/components/templates/reconciliation.tsx" /* webpackChunkName: "component---src-components-templates-reconciliation-tsx" */),
  "component---src-components-templates-resources-tsx": () => import("./../../../src/components/templates/resources.tsx" /* webpackChunkName: "component---src-components-templates-resources-tsx" */),
  "component---src-components-templates-steering-tsx": () => import("./../../../src/components/templates/steering.tsx" /* webpackChunkName: "component---src-components-templates-steering-tsx" */),
  "component---src-components-templates-why-orchestration-tsx": () => import("./../../../src/components/templates/whyOrchestration.tsx" /* webpackChunkName: "component---src-components-templates-why-orchestration-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

