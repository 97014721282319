import React, { createContext, ReactNode, useMemo } from 'react';
import {
	useAmountLine,
	usePayButton,
	useDisplayMode,
	useBackgroundColor,
	useColor,
	useTypography
} from '../components/hooks/widgetDemo';

const WidgetDemoContext = createContext<any>({
	backgroundColor: '#ffffff',
	color: '#004AFF',
	payButton: true,
	amountLine: true,
	displayMode: {
		value: 'list',
		label: 'Liste'
	},
	typography: {
		value: 'Roboto',
		label: 'Roboto'
	}
});

function WidgetDemoProvider({ children }: { children: ReactNode }) {
	const [backgroundColor, setBackgroundColor] = useBackgroundColor();
	const [color, setColor] = useColor();
	const [payButton, setPayButton] = usePayButton();
	const [amountLine, setAmountLine] = useAmountLine();
	const [displayMode, setDisplayMode] = useDisplayMode();
	const [typography, setTypography] = useTypography();
	const value = useMemo(
		() => ({
			color,
			setColor,
			backgroundColor,
			setBackgroundColor,
			typography,
			setTypography,
			payButton,
			setPayButton,
			amountLine,
			setAmountLine,
			displayMode,
			setDisplayMode
		}),
		[color, backgroundColor, typography, payButton, amountLine, displayMode]
	);
	return <WidgetDemoContext.Provider value={value}>{children}</WidgetDemoContext.Provider>;
}

export default WidgetDemoContext;
export { WidgetDemoProvider };
