import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import commonFR from '../assets/locales/fr/common.json';
import commonEN from '../assets/locales/en/common.json';
import commonES from '../assets/locales/es/common.json';

i18n.use(initReactI18next).init({
	lng: 'fr',
	fallbackLng: 'fr',
	whitelist: ['fr', 'en', 'es'],
	interpolation: {
		escapeValue: false
	},
	resources: {
		en: {
			translation: commonEN
		},
		fr: {
			translation: commonFR
		},
		es: {
			translation: commonES
		}
	}
});

export default i18n;
