import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { changeLanguage as changeLanguageI18n } from 'i18next';
import '../config/i18n';

export interface SupportedLanguagesType {
	code: string;
	language: string;
}
export interface LanguageContextType {
	language: string;
	supportedLanguages: SupportedLanguagesType[];
	changeLanguage: (newLanguage: string) => void;
}

const defaultValue: LanguageContextType = {
	language: '',
	supportedLanguages: [],
	changeLanguage: (newLanguage: string) => {}
};

const LanguageContext = createContext<LanguageContextType>(defaultValue);

function LanguageProvider({ children }: { children: ReactNode }) {
	const supportedLanguages = [
		{ code: 'fr', language: 'Français' },
		{ code: 'en', language: 'English' },
		{ code: 'es', language: 'Español' }
	];
	const defaultLanguage = 'fr';
	const languageInPath = () => {
		let urlObj;
		if (typeof window !== 'undefined') {
			urlObj = new URL(window.location.href);
		}
		let path = urlObj?.pathname;
		return path?.substring(1, 3);
	};
	const languageIsoLanguagePath =
		typeof window !== 'undefined' &&
		languageInPath() &&
		window.navigator.language.substring(0, 2) !== languageInPath()
			? languageInPath()
			: window.navigator.language.substring(0, 2);
	const currentLanguage = languageIsoLanguagePath ? languageIsoLanguagePath : defaultLanguage;

	const [language, setLanguage] = useState(
		supportedLanguages.find(lng => lng.code === currentLanguage) ? currentLanguage : defaultLanguage
	);

	const changeLanguage = (newLanguage: string) => {
		setLanguage(newLanguage);
	};

	useEffect(() => {
		changeLanguageI18n(language);
	}, [language]);

	const value = { language, supportedLanguages, changeLanguage };

	return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
}

export default LanguageContext;
export { LanguageProvider };
